import { Poppins, Quicksand } from 'next/font/google'
import React from 'react'
const quickSand=Quicksand({weight:"500",subsets:['latin']})
const popins=Poppins({weight:"400",subsets:['latin']})
import CountUp from 'react-countup';

export default function Rectancle() {
  return (
    <section className='mb30'>
        <div className='container'>
    <div className='mdf mfjc df'>
    <div className='rect-box df fjc fjse fac mfdc'>
         
               <div className='df fdc fac'>
                <h4 className={`dtls-fs ${popins.className}`}><CountUp end={3342} duration={3} />+</h4>
                <h6 className={`dtls-fs-1  ${popins.className}`}>Enlisted Students</h6>               
                </div>
                 <div className='liner-code mdn'></div>
                <div className='df fdc fac'>
                  <h4 className={`dtls-fs ${popins.className}`} >
                <CountUp end={1439} duration={3} />+</h4>
                <h6 className={`dtls-fs-1  ${popins.className}`}>Course Finished</h6>
                </div> 
                <div className='liner-code mdn'></div>
                <div className='df fdc fac'>
                    <h4 className={`dtls-fs ${popins.className}`}><CountUp end={40} duration={3} />+</h4>
                <h6 className={`dtls-fs-1  ${popins.className}`}>Expert Instructor</h6>
                </div>
                <div className='liner-code mdn'></div>
                <div className='df fdc fac'>
                    <h4 className={`dtls-fs ${popins.className}`}><CountUp end={80} duration={3} />+</h4>
                <h6 className={`dtls-fs-1  ${popins.className}`}>Courses in Technology</h6>
                </div>
                </div>
                </div>
                </div>
               <style jsx>
                {
                  `
                  .liner-code{
                    width:3px;
                    height:153px;
                    background: linear-gradient(to bottom, #FFFFFF 50%, #D9D9D9 0%);
                    // margin-left:56px;
                }
                  
                .rect-box{
                  width:100%;
                  height:318px;
                  background-color:#FFA900;
                  margin-top:32.68px;
                  text-align:center;
                  border-radius:50px;
                  border: 2px solid #FFFFFF;
                  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.25);
              
                 }
                 .dtls-fs{
                  font-size:45px;
                  line-height: 53px;
                  letter-spacing: 0.25px;
                  color:#FFFFFF;
              }
              .dtls-fs-1{
                  font-size:19px;
                  font-weight:500;
                  line-height:27px;
                  letter-spacing:0.15px;
                  color:#FFFFFF;
                  margin-top:40px;
              
              }

          @media only screen and (max-width: 1023px){


               .dtls-fs{
                  font-size:19px;
                  line-height: 27px;
                  letter-spacing: 0.25px;
                  color:#FFFFFF;
              }
            .rect-box{
              width:100%;
              height:400px;
              background-color:#FFA900;
              margin-top:px;
              text-align:center;
              border-radius:40px;
              
            
             }
          
           .dtls-fs-1{
            font-size:12px;
            font-weight:500;
            line-height:23px;
            letter-spacing:0.15px;
            color:#FFFFFF;
            margin-top:8px;
        
        }


          }


                 `
                }
                
                </style> 
                </section>
  )
}
