import React from "react";
import Image from "next/image";
import { Poppins, Quicksand } from "next/font/google";

const popins = Poppins({ weight: "400", subsets: ["latin"] });
const quickSand = Quicksand({ weight: "400", subsets: ["latin"] });
export default function CourseCard() {
  const data = [
    {
      img: "/course-img/Our-Course-Card-img/dev-img.webp",
      heading: "Development",
    },
    {
      img: "/course-img/Our-Course-Card-img/prgm-img.webp",
      heading: "Programming",
    },
    {
      img: "/course-img/Our-Course-Card-img/clud-img.webp",
      heading: "Cloud Computing",
    },
    {
      img: "/course-img/Our-Course-Card-img/design-img.webp",
      heading: "Designing",
    },
    {
      img: "/course-img/Our-Course-Card-img/erp-img.webp",
      heading: "ERP",
    },
    {
      img: "/course-img/Our-Course-Card-img/testing-img.webp",
      heading: "Testing",
    },
  ];

  return (
    <section >
      {/* <CircleBox title={"Top Categories"} width={"351px"} height={"70px"} /> */}
<div className="container ">
  <div className="mdn">
<div className="df fjc fac mt48">
            <div className="top-cate df fac fjsa">
              <Image
                className="img"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
              <h4 className={`fs-33 ${popins.className}`}>Top Categories</h4>
              <Image
                className="img"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
            </div>
          </div>
      <div className="df fw fjse mt48 ">
        {data.map((items, index) => {
          return (
            <div key={index} className="col mb mb19">
              <div className="course-card df fw ">
                <div key={index} className="df fdc">
                  <div className="df">
                    <div className="card-bg">
                      <Image
                        className=""
                        src={items.img}
                        alt={items.heading}
                        width={"167"}
                        height={"141"}
                        loading="lazy"
                      />
                    </div>
                    <div className="">
                      <p
                        className={`course-side-box  fs-14 ylw-clr pd5 mt22 ${quickSand.className}`}
                      >
                        14 Courses
                      </p>
                    </div>
                  </div>
                  <div className="course-bottom-box mt12 df fjc fac">
                    <h6 className={` fs-19 cw ${popins.className}`}>
                      {items.heading}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </div></div>
      <style jsx>
        {`
          .cate-tit {
            font-size: 33px;
            letter-spacing: 0.25px;
            line-height: 41px;
            color: #000000;
          }

          .col {
            width: 400px;
            
          }
      
          .course-box-tit {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: #ffa900;
            margin-top: 22px;
          }
          .course-side-box {
            width: 75px;
            height: 22px;
            background-color: #f3f3f3;
            border: 0.5px solid #ffa900;
            border-radius: 8px;
          }
          .course-bottom-box {
            border-radius: 0px 0px 30px 30px;
            background-color: #ffa900;
            width: 400px;
            height: 70px;
            cursor: pointer;
          }
          .card-bg {
            background-size: cover;
            background-image: url("/course-img/Our-Course-Card-img/card-bg.webp");
            background-repeat: no-repeat;
            width: 167px;
            height: 145px;
            margin-top: 24px;
            margin-left: 116px;
            transition: transform 0.3s ease-in-out;
            cursor: pointer;
          }
          .course-bg:hover {
            .card-bg {
              background-size: cover;
              background-image: url("/course-img/Our-Course-Card-img/card-bg.webp");
              background-repeat: no-repeat;
              width: 167px;
              height: 145px;
              margin-top: 24px;
              margin-left: 116px;
              transition: transform 0.3s ease-in-out;
              cursor: pointer;
            }
            .course-bg:hover {
              .card-bg {
                background-size: cover;
                background-image: url("/course-img/Our-Course-Card-img/card-bg.webp");
                background-repeat: no-repeat;
                width: 167px;
                height: 145px;
                margin-top: 24px;
                margin-left: 116px;
                transition: transform 0.3s ease-in-out;
                cursor: pointer;
              }
  
            }
          }
          .course-card {
            width: 400px;
            height: 250px;
            background-color: #ffffff;
            border-radius: 0px 0px 30px 30px;
            box-shadow: 0px 10px 30px 0px rgba(255, 169, 0, 0.3);
          }
          
          .top-cate {
            background-color: #ffffff;
            width: 351px;
            height: 70px;
            border: 1px solid #fdbb39;
            border-radius: 46px;
            box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
          }

          @media only screen and (max-width: 1223px) {
            .row {
              justify-content: center;
              gap: 25px;
            }
            
          }
        `}
      </style>
    </section>
  );
}
{
  /* <h4 className={`fs33 ylw lnh41 ls025 ${popins.className}`}>Quick Enquiry</h4>
<p className={`dim-clr fs16 lnh24 ls05${quickSand.className}`}>Please fill the form before</p> */
}
